import { LocationsService, JLocationShort } from './../../services/locations.service';
import { Component, OnInit } from '@angular/core';
import { AdditionalJobsService } from '../../services/additionaljobs';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-additionaljobs',
  templateUrl: './additionaljobs.component.html',
  styleUrls: ['./additionaljobs.component.scss'],
})
export class AdditionaljobsComponent implements OnInit {

  currentActive = localStorage.getItem('currentActive') ? localStorage.getItem('currentActive') : 'jobs';
  config_object = null;
  loc_job       = null

  setActive(n) {
    localStorage.setItem('currentActive', n)
    this.currentActive = n;
  }

  constructor(private helper: HelperService,
              private jobsService: AdditionalJobsService
              ) { }

  ngOnInit() {
    this.jobsService.GetJobsConfig().then(x=>{
      this.listToDict(x)
      this.config_object=x;
    });
  }

  listToDict(l){
    this.loc_job={}
    for (let key in l.additionals) {
      this.loc_job[l.additionals[key]]=[]
    }
    l.config.forEach(x=>{
      this.loc_job[x[0]].push(x[1])
    })
  }

  check(ad, loc){
    if(this.loc_job[ad].includes(loc)){
      this.loc_job[ad].splice(this.loc_job[ad].indexOf(loc),1)
    }else{
      this.loc_job[ad].push(loc)
    }
  }

  async doSave() {
    this.jobsService.SetJobsConfig(this.loc_job).then(x=>{
        this.helper.notify('Your configuration has been saved', 'success')
      }
    ).catch( x=>{
        this.helper.notify('Saving this configuration has failed', 'danger')
      }
    )
  }

}
