import { Component, Inject, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfirmDelete } from '../../decorators/confirmdelete.decorator';
import { JService } from '../../services/services.service';

@Component({
    selector: 'app-servicesedit',
    template: `<ion-header>
            <ion-toolbar>
                <ion-title>{{formdata.id?"Edit":"New"}} Service</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="save()">
                        <ion-icon slot="icon-only" name="save"></ion-icon>
                    </ion-button>
                    <ion-button (click)="close()">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-no-padding">
            <ion-list>
                <ion-item>
                    <ion-label position="floating">Car Line</ion-label>
                    <ion-input [(ngModel)]="formdata.carLine"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">Kms</ion-label>
                    <ion-input [(ngModel)]="formdata.kms"></ion-input>
                </ion-item>
                <br />
                <ion-item>
                    <ion-label position="floating">Service text EN</ion-label>
                    <ion-input [(ngModel)]="formdata.service_eng"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">Service text AR</ion-label>
                    <ion-input [(ngModel)]="formdata.service_arb"></ion-input>
                </ion-item>
                <br />
                <ion-item>
                    <ion-label position="floating">Operation code</ion-label>
                    <ion-input [(ngModel)]="formdata.realCode"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">Real price</ion-label>
                    <ion-input [(ngModel)]="formdata.realPrice"></ion-input>
                </ion-item>
                <br />
                <ion-item>
                    <ion-label position="floating">Specification</ion-label>
                    <ion-input [(ngModel)]="formdata.specification"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">Real duration (minutes)</ion-label>
                    <ion-input [(ngModel)]="formdata.realDuration"></ion-input>
                </ion-item>
                <br />
            </ion-list>
        
            <ion-list>
                <ion-item (click)="delete()" button>
                    <ion-label color="danger">Delete</ion-label>
                    <ion-icon color="danger" slot="end" name="trash"></ion-icon>
                </ion-item>
            </ion-list>
        </ion-content>`,
    styleUrls: []
})
export class ServicesEditDialog {

    constructor(private modalController: ModalController) {

    }

    @Input("formdata") formdata: JService = {
        id: undefined,
        carLine: "",
        kms: 0,
        service_eng: "",
        service_arb: "",
        realCode: "",
        realPrice: "",
        specification: "",
        realDuration: 0
      };

    @ConfirmDelete()
    delete() {
        this.modalController.dismiss({ wantsDelete: true });
    }

    close() {
        this.modalController.dismiss();
    }

    save() {
        this.modalController.dismiss({ wasSaved: true, data: this.formdata });
    }
}
