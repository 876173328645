import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-datetimechooser',
  templateUrl: './datetimechooser.component.html',
  styleUrls: ['./datetimechooser.component.scss'],
})
export class DatetimechooserDialog implements OnInit {

  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;

  @Input("date") datevalue: DateTime = DateTime.local().setZone("UTC");
  @Input("dateOnly") dateOnly = false;

  clickedDone = false;

  startValue = "1992-01-24T23:47:20.789+02:00"; //"YYYY-MM-DDTHH:mm:ssTZD"

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.startValue = this.datevalue.toISO();
    console.log(this.datevalue.toISO());
  }

  cancel() {
    this.modalController.dismiss();
  }

  onDTChanged(e) {
    if (this.clickedDone) {
      console.log(e.detail.value)
      let d = new Date(e.detail.value);
      let nd = this.datevalue;
      nd = nd.set({year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate()})
      if (!this.dateOnly) {
        nd = nd.set({hour: d.getUTCHours(), minute: d.getMinutes(), second: d.getSeconds(), millisecond: d.getMilliseconds()})
      }
      this.modalController.dismiss({date: nd});
    }
  }
  
  async done() {
    this.clickedDone = true;
    this.datetime.confirm()
  }

}
