import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { fader } from './route-animation';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [
    fader
  ]
})
export class AppComponent {
  logoUrl = "https://alcrveshtambcsaxrded.supabase.co/storage/v1/object/public/demofiles/OsbLogo.png"

  constructor(public auth: AuthService) {}

  menuOpened(e) {
    var event = new MouseEvent('mouseover', {
      'view': window,
      'bubbles': true,
      'cancelable': true
    });
    e.target.dispatchEvent(event);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData
  }
}
