import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';
import { JLocation } from './locations.service';

@Injectable({
  providedIn: 'root'
})
export class AdditionalJobsService {

  constructor(private http : HttpClient) { }

  /**
   * Get
   */
  public GetJobs() {
    return this.http.get<AdditionalJob[]>(environment.ApiURL+'/additionaljob/jobs',{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<AdditionalJob[]>()
  }

  public GetConcerns() {
    return this.http.get<AdditionalJob[]>(environment.ApiURL+'/additionaljob/concerns',{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<AdditionalJob[]>()
  }

  public GetJobsConfig(){
    return this.http.get<JobConfig>(environment.ApiURL+'/additionaljob/getjobconfig',{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<JobConfig>()
  }

  public SetJobsConfig(config: number[][]){
    return this.http.post< number[][]>(environment.ApiURL+'/additionaljob/setjobconfig', config, {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<number[][]>()
  }

  /**
   * Get
   */
  public GetOne(id:number) {
    return this.http.get<AdditionalJob>(environment.ApiURL+'/additionaljob/'+id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<AdditionalJob>()
  }

  /**
   * Post
   */
  public Post(job: AdditionalJob) {
    return this.http.post<AdditionalJob>(
      environment.ApiURL+'/additionaljob',
      job,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<AdditionalJob>()
  }

  /**
   * Put
   */
  public Put(job: AdditionalJob) {
    return this.http.put<any>(
      environment.ApiURL+'/additionaljob',
      job,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<any>()
  }

  /**
   * Delete
   */
  public Delete(id:number) {
    return this.http.delete<any>(environment.ApiURL+'/additionaljob/delete/'+id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<any>()
  }
}

export interface AdditionalJob {
  id?: number|undefined;
  operationCode: string;
  duration: number|undefined;
  name: string;
  nameAr: string;
  customerTooltip: string;
  price: string;
  vinFilter: string;
  locations: JLocation[];
  isConcern: boolean;
}

export interface JobConfig {
  additionals: any;
  locations: any;
  config: any;
}
