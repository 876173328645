import { Component, Inject, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { AdditionalJob, AdditionalJobsService } from '../../../services/additionaljobs';
import { HelperService } from '../../../services/helper.service';
import { JLocationShort, LocationsService } from '../../../services/locations.service';
import { FilterDialog } from './filter.component';

@Component({
    selector: 'app-jobedit',
    templateUrl: './jobsedit.component.html',
  styleUrls: ['./jobsedit.component.scss'],
})
export class JobEditDialog implements OnInit {

    LocationList: BehaviorSubject<JLocationShort[]|undefined> = new BehaviorSubject<JLocationShort[]|undefined>(undefined);

    constructor(private modalController: ModalController, private helper: HelperService, private locationService: LocationsService) {

    }

    ngOnInit(): void {
        this.locationService.GetShort().then(v => {
            this.LocationList.next(v);
          }, r => {
            this.helper.notify("Could not load location data","danger")
          })
    }

    @Input('isconcern') isconcern = false;
    @Input("formdata") formdata: AdditionalJob = {
        id: undefined,
        operationCode: '',
        duration: undefined,
        name: '',
        nameAr: '',
        customerTooltip: '',
        price: '',
        vinFilter: '',
        locations: [],
        isConcern: this.isconcern
    };

    getFilters() {
        return this.formdata.vinFilter == '' ? [] : this.formdata.vinFilter.split(',')
    }

    @ConfirmDelete()
    delFilter(i) {
        let filters = this.getFilters();
        filters.splice(i, 1);
        this.formdata.vinFilter = filters.join(',');
    }

    async editFilter(idx=-1) {
        let res = await this.helper.createAndOpenModal(FilterDialog, {filter: idx < 0 ? '*****************' : this.getFilters()[idx]}, 'doubled-modal filter-modal')
        if (res?.data?.f) {
            if (idx < 0) {
                this.formdata.vinFilter += (this.formdata.vinFilter == '' ? '' : ',') + res.data.f;
            } else {
                let filters = this.getFilters();
                filters[idx] = res.data.f;
                this.formdata.vinFilter = filters.join(',');
            }
        }
    }

    @ConfirmDelete()
    delete() {
        this.modalController.dismiss({ wantsDelete: true });
    }

    close() {
        this.modalController.dismiss();
    }

    save() {
        this.formdata.isConcern = this.isconcern;
        this.modalController.dismiss({ wasSaved: true, data: this.formdata });
    }
}
