import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    selector: 'app-filter',
    template: `<ion-header>
            <ion-toolbar>
                <ion-title>Filter</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="save()">
                        <ion-icon slot="icon-only" name="save"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <strong>Please select a position in the VIN Number to change the filter for that position.</strong>
            <br />
            <br />
            <table class="table">
                <tr>
                    <td>Pos:</td>
                    <td class="vinpart" [class.active]="CurPos==0" (click)="setPos(0)">1</td>
                    <td class="vinpart" [class.active]="CurPos==1" (click)="setPos(1)">2</td>
                    <td class="vinpart" [class.active]="CurPos==2" (click)="setPos(2)">3</td>
                    <td class="vinpart" [class.active]="CurPos==3" (click)="setPos(3)">4</td>
                    <td class="vinpart" [class.active]="CurPos==4" (click)="setPos(4)">5</td>
                    <td class="vinpart" [class.active]="CurPos==5" (click)="setPos(5)">6</td>
                    <td class="vinpart" [class.active]="CurPos==6" (click)="setPos(6)">7</td>
                    <td class="vinpart" [class.active]="CurPos==7" (click)="setPos(7)">8</td>
                    <td class="vinpart" [class.active]="CurPos==8" (click)="setPos(8)">9</td>
                    <td class="vinpart" [class.active]="CurPos==9" (click)="setPos(9)">10</td>
                    <td class="vinpart" [class.active]="CurPos==10" (click)="setPos(10)">12</td>
                    <td class="vinpart" [class.active]="CurPos==12" (click)="setPos(12)">13</td>
                    <td class="vinpart" [class.active]="CurPos==13" (click)="setPos(13)">14</td>
                    <td class="vinpart" [class.active]="CurPos==14" (click)="setPos(14)">15</td>
                    <td class="vinpart" [class.active]="CurPos==15" (click)="setPos(15)">16</td>
                    <td class="vinpart" [class.active]="CurPos==16" (click)="setPos(16)">17</td>
                </tr>
                <tr>
                    <td></td>
                    <td class="vinpart" [class.active]="CurPos==0" (click)="setPos(0)">{{VinDigits[0]}}</td>
                    <td class="vinpart" [class.active]="CurPos==1" (click)="setPos(1)">{{VinDigits[1]}}</td>
                    <td class="vinpart" [class.active]="CurPos==2" (click)="setPos(2)">{{VinDigits[2]}}</td>
                    <td class="vinpart" [class.active]="CurPos==3" (click)="setPos(3)">{{VinDigits[3]}}</td>
                    <td class="vinpart" [class.active]="CurPos==4" (click)="setPos(4)">{{VinDigits[4]}}</td>
                    <td class="vinpart" [class.active]="CurPos==5" (click)="setPos(5)">{{VinDigits[5]}}</td>
                    <td class="vinpart" [class.active]="CurPos==6" (click)="setPos(6)">{{VinDigits[6]}}</td>
                    <td class="vinpart" [class.active]="CurPos==7" (click)="setPos(7)">{{VinDigits[7]}}</td>
                    <td class="vinpart" [class.active]="CurPos==8" (click)="setPos(8)">{{VinDigits[8]}}</td>
                    <td class="vinpart" [class.active]="CurPos==9" (click)="setPos(9)">{{VinDigits[9]}}</td>
                    <td class="vinpart" [class.active]="CurPos==10" (click)="setPos(10)">{{VinDigits[10]}}</td>
                    <td class="vinpart" [class.active]="CurPos==12" (click)="setPos(12)">{{VinDigits[12]}}</td>
                    <td class="vinpart" [class.active]="CurPos==13" (click)="setPos(13)">{{VinDigits[13]}}</td>
                    <td class="vinpart" [class.active]="CurPos==14" (click)="setPos(14)">{{VinDigits[14]}}</td>
                    <td class="vinpart" [class.active]="CurPos==15" (click)="setPos(15)">{{VinDigits[15]}}</td>
                    <td class="vinpart" [class.active]="CurPos==16" (click)="setPos(16)">{{VinDigits[16]}}</td>
                </tr>
            </table>

            <br />
            <br />
            <strong>Please select the type of filter that needs to be applied on position {{CurPos+1}}</strong>
            <br />
            <br />
            <ion-grid fixed>
                <ion-row>
                    <ion-col size="4">
                        <ion-item>
                            <ion-label position="floating">Filter type</ion-label>
                            <ion-select interface="popover" [(ngModel)]="SelectedMode" (ionChange)="selectedModeChanged($event)">
                                <ion-select-option value="all">
                                    Every character is accepted
                                </ion-select-option>
                                <ion-select-option value="specific">
                                    Accept a specific character
                                </ion-select-option>
                                <ion-select-option value="range">
                                    Accept a character range
                                </ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>
                    <ion-col *ngIf="SelectedMode=='specific'" size="8">
                        <ion-item>
                            <ion-label position="floating">Character</ion-label>
                            <ion-select interface="popover" [(ngModel)]="VinDigits[CurPos]">
                                <ion-select-option value="0">0</ion-select-option><ion-select-option value="1">1</ion-select-option><ion-select-option value="2">2</ion-select-option><ion-select-option value="3">3</ion-select-option><ion-select-option value="4">4</ion-select-option><ion-select-option value="5">5</ion-select-option>
                                <ion-select-option value="6">6</ion-select-option><ion-select-option value="7">7</ion-select-option><ion-select-option value="8">8</ion-select-option><ion-select-option value="9">9</ion-select-option><ion-select-option value="A">A</ion-select-option><ion-select-option value="B">B</ion-select-option>
                                <ion-select-option value="C">C</ion-select-option><ion-select-option value="D">D</ion-select-option><ion-select-option value="E">E</ion-select-option><ion-select-option value="F">F</ion-select-option><ion-select-option value="G">G</ion-select-option><ion-select-option value="H">H</ion-select-option>
                                <ion-select-option value="I">I</ion-select-option><ion-select-option value="J">J</ion-select-option><ion-select-option value="K">K</ion-select-option><ion-select-option value="L">L</ion-select-option><ion-select-option value="M">M</ion-select-option><ion-select-option value="N">N</ion-select-option>
                                <ion-select-option value="O">O</ion-select-option><ion-select-option value="P">P</ion-select-option><ion-select-option value="H">H</ion-select-option><ion-select-option value="I">I</ion-select-option><ion-select-option value="J">J</ion-select-option><ion-select-option value="K">K</ion-select-option>
                                <ion-select-option value="L">L</ion-select-option><ion-select-option value="M">M</ion-select-option><ion-select-option value="N">N</ion-select-option><ion-select-option value="O">O</ion-select-option><ion-select-option value="P">P</ion-select-option><ion-select-option value="Q">Q</ion-select-option>
                                <ion-select-option value="R">R</ion-select-option><ion-select-option value="S">S</ion-select-option><ion-select-option value="T">T</ion-select-option><ion-select-option value="U">U</ion-select-option><ion-select-option value="V">V</ion-select-option><ion-select-option value="W">W</ion-select-option>
                                <ion-select-option value="X">X</ion-select-option><ion-select-option value="Y">Y</ion-select-option><ion-select-option value="Z">Z</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>
                    <ng-container *ngIf="SelectedMode=='range'">
                        <ion-col size="4">
                            <ion-item>
                                <ion-label position="floating">From character</ion-label>
                                <ion-select interface="popover" [(ngModel)]="RangeFrom" (ionChange)="onPopChange($event, true)">
                                    <ion-select-option value="0">0</ion-select-option><ion-select-option value="1">1</ion-select-option><ion-select-option value="2">2</ion-select-option><ion-select-option value="3">3</ion-select-option><ion-select-option value="4">4</ion-select-option><ion-select-option value="5">5</ion-select-option>
                                    <ion-select-option value="6">6</ion-select-option><ion-select-option value="7">7</ion-select-option><ion-select-option value="8">8</ion-select-option><ion-select-option value="9">9</ion-select-option><ion-select-option value="A">A</ion-select-option><ion-select-option value="B">B</ion-select-option>
                                    <ion-select-option value="C">C</ion-select-option><ion-select-option value="D">D</ion-select-option><ion-select-option value="E">E</ion-select-option><ion-select-option value="F">F</ion-select-option><ion-select-option value="G">G</ion-select-option><ion-select-option value="H">H</ion-select-option>
                                    <ion-select-option value="I">I</ion-select-option><ion-select-option value="J">J</ion-select-option><ion-select-option value="K">K</ion-select-option><ion-select-option value="L">L</ion-select-option><ion-select-option value="M">M</ion-select-option><ion-select-option value="N">N</ion-select-option>
                                    <ion-select-option value="O">O</ion-select-option><ion-select-option value="P">P</ion-select-option><ion-select-option value="H">H</ion-select-option><ion-select-option value="I">I</ion-select-option><ion-select-option value="J">J</ion-select-option><ion-select-option value="K">K</ion-select-option>
                                    <ion-select-option value="L">L</ion-select-option><ion-select-option value="M">M</ion-select-option><ion-select-option value="N">N</ion-select-option><ion-select-option value="O">O</ion-select-option><ion-select-option value="P">P</ion-select-option><ion-select-option value="Q">Q</ion-select-option>
                                    <ion-select-option value="R">R</ion-select-option><ion-select-option value="S">S</ion-select-option><ion-select-option value="T">T</ion-select-option><ion-select-option value="U">U</ion-select-option><ion-select-option value="V">V</ion-select-option><ion-select-option value="W">W</ion-select-option>
                                    <ion-select-option value="X">X</ion-select-option><ion-select-option value="Y">Y</ion-select-option><ion-select-option value="Z">Z</ion-select-option>
                                </ion-select>
                            </ion-item>
                        </ion-col>
                        <ion-col size="4">
                            <ion-item>
                                <ion-label position="floating">Till character</ion-label>
                                <ion-select interface="popover" [(ngModel)]="RangeTill" (ionChange)="onPopChange($event, false)">
                                    <ion-select-option value="0">0</ion-select-option><ion-select-option value="1">1</ion-select-option><ion-select-option value="2">2</ion-select-option><ion-select-option value="3">3</ion-select-option><ion-select-option value="4">4</ion-select-option><ion-select-option value="5">5</ion-select-option>
                                    <ion-select-option value="6">6</ion-select-option><ion-select-option value="7">7</ion-select-option><ion-select-option value="8">8</ion-select-option><ion-select-option value="9">9</ion-select-option><ion-select-option value="A">A</ion-select-option><ion-select-option value="B">B</ion-select-option>
                                    <ion-select-option value="C">C</ion-select-option><ion-select-option value="D">D</ion-select-option><ion-select-option value="E">E</ion-select-option><ion-select-option value="F">F</ion-select-option><ion-select-option value="G">G</ion-select-option><ion-select-option value="H">H</ion-select-option>
                                    <ion-select-option value="I">I</ion-select-option><ion-select-option value="J">J</ion-select-option><ion-select-option value="K">K</ion-select-option><ion-select-option value="L">L</ion-select-option><ion-select-option value="M">M</ion-select-option><ion-select-option value="N">N</ion-select-option>
                                    <ion-select-option value="O">O</ion-select-option><ion-select-option value="P">P</ion-select-option><ion-select-option value="H">H</ion-select-option><ion-select-option value="I">I</ion-select-option><ion-select-option value="J">J</ion-select-option><ion-select-option value="K">K</ion-select-option>
                                    <ion-select-option value="L">L</ion-select-option><ion-select-option value="M">M</ion-select-option><ion-select-option value="N">N</ion-select-option><ion-select-option value="O">O</ion-select-option><ion-select-option value="P">P</ion-select-option><ion-select-option value="Q">Q</ion-select-option>
                                    <ion-select-option value="R">R</ion-select-option><ion-select-option value="S">S</ion-select-option><ion-select-option value="T">T</ion-select-option><ion-select-option value="U">U</ion-select-option><ion-select-option value="V">V</ion-select-option><ion-select-option value="W">W</ion-select-option>
                                    <ion-select-option value="X">X</ion-select-option><ion-select-option value="Y">Y</ion-select-option><ion-select-option value="Z">Z</ion-select-option>
                                </ion-select>
                            </ion-item>
                        </ion-col>
                    </ng-container>
                </ion-row>
            </ion-grid>

        </ion-content>`,
    styles: [`
        .vinpart {
            cursor: pointer;
            text-align: center;
            vertical-align: middle;
            min-width: 50px;
            /* height: 50px; */
        }

        .active {
            background-color: #3880ff;
            color: #ffffff;
        }
    `]
})
export class FilterDialog implements OnInit {

    CurPos = 0
    SelectedMode = "all"
    RangeFrom = "9"
    RangeTill = "A"

    constructor(private modalController: ModalController) {

    }

    @Input('filter') filter = "";

    VinDigits = [
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
        "*",
    ]

    ngOnInit(): void {
        if (this.filter == "" || this.filter == null || this.filter == undefined) {
            this.filter = "*****************"
        }
        //*[9-A]*************3*
        let lVinDigits: string[] = [];
        for (let i = 0; i < this.filter.length; i++) {
            if (this.filter[i] == "*") {
                lVinDigits.push("*");
            } else if (this.filter[i] == "[" && this.filter.length - i >= 5) {
                lVinDigits.push(this.filter[i + 1] + '-' + this.filter[i + 3]);
                i += 4;
            } else {
                lVinDigits.push(this.filter[i])
            }
        }
        while (lVinDigits.length < 17) {
            console.log("Add some");
            lVinDigits.push("*")
        }
        if (lVinDigits.length > 17) {
            console.log("Delete some");
            lVinDigits.splice(17, lVinDigits.length - 17)
        }
        this.VinDigits = lVinDigits
        this.setPos(0)
    }

    save() {
        console.log(this.generateCode())
        this.modalController.dismiss({f:this.generateCode()});
    }

    onPopChange(event: any, isTill: boolean) {
        this.VinDigits[this.CurPos]=event.detail.value+'-'+(isTill ? this.RangeTill : this.RangeFrom)
    }

    generateCode() {
        let val = "";
        this.VinDigits.forEach(v => {
            if (v.includes("-")) {
                val += "[" + v + "]"
            } else {
                val += v
            }
        })
        return val
    }

    setPos(pos) {
        if (this.VinDigits[pos].includes('-')) {
            this.SelectedMode = "range"
        } else if (this.VinDigits[pos].includes('*')) {
            this.SelectedMode = "all"
        } else {
            this.SelectedMode = "specific"
        }
        this.CurPos = pos;
    }

    selectedModeChanged(event) {
        if (event.detail.value == "all") {
            this.VinDigits[this.CurPos] = "*"
        } else if (event.detail.value == "specific") {
            this.VinDigits[this.CurPos] = "0"
        } else if (event.detail.value == "range") {
            this.RangeFrom = "9"
            this.RangeTill = "A"
            this.VinDigits[this.CurPos] = "9-A"
        }
    }
}