import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JSettingsService {

  constructor(private http : HttpClient) { }

  /**
   * Get
   */
  public Get() {
    return this.http.get<JSetting>(environment.ApiURL+'/setting', {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<JSetting>()
  }

  /**
   * Put
   */
  public Put(setting: JSetting) {
    if (setting.uploadNewPic != true) {
      setting.companyLogo = "";
    }
    return this.http.put<any>(
      environment.ApiURL+'/setting',
      setting,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<any>()
  }

  public switchExternal(externalConfigstate: ExternalConfigState) {
    //convert array to object, and stringify
    externalConfigstate.externalList.forEach(e=>{
      let d = {};
      (e.data as any[]).forEach(v=>{
        d[v[3]]={
          type:v[0],
          label:v[1],
          value:v[2]
        }
      });
      e.data = JSON.stringify(d)
    })
    return this.http.post<ExternalConfigState>(`${environment.ApiURL}/externalconfig`, externalConfigstate, {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<ExternalConfigState>()
  }
  
  public getExternal(){
    return this.http.get<ExternalConfigState>(`${environment.ApiURL}/externalconfig/list`, {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<ExternalConfigState>()
  }
}

export interface JSetting {
  companyLogo: string;
  uploadNewPic?:boolean;
  smsConfirmationText: string;
  smsUpdatedText: string;
  smsReminderText: string;
  smsConfirmationTextAr: string
  smsReminderTextAr: string
  smsUpdatedTextAr: string
  useSpreadPayment: number;
}

export interface ExternalConfig {
  data:string | any[],
  name: string,
  id: number,
  logo: string
}

export interface ExternalConfigState {
  externalList: ExternalConfig[];
  selectedExternal: number;
}
