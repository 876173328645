import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { AuthService, UserData } from '../../services/auth.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {

  provider_id: string | undefined = undefined
  userdata: UserData|undefined = undefined
  subscription:Subscription;

  constructor(private auth: AuthService, private helper: HelperService) { }

  ngOnInit() {
    this.userdata = this.auth.AuthObject.value;
    this.subscription = this.auth.AuthObject.subscribe(v => {
      this.userdata = v;
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  signOut() {
    this.helper.notify('Signing out', 'dark')
    this.auth.logout()
  }

  refreshPermissions() {
    this.auth.refresh();
  }

  alpha=["q","e","r","t","u","i","v","o","p","m"]
  beta=["a","s","y","d","z","f","c","h","k","j"]

  amenc(inp:string) {
    if (inp.length <= 1)
      return this.alpha[Number.parseInt(inp[0])];
    else if (inp.length % 2 == 0)
      return this.alpha[Number.parseInt(inp[0])] + this.amenc(inp.substring(1))
    else
      return this.beta[Number.parseInt(inp[0])] + this.amenc(inp.substring(1))
  }

  gotoAdvanced() {
    if (this.userdata) {
      if (this.userdata.eid) {
        let alocation = this.userdata.roles.find(r => r.startsWith('location'));
        if (alocation) {
          alocation = alocation.substring(8);
        }
        window.open("https://osb.release.iq-motive.com/?"+(alocation?"preloc="+alocation+"&":"")+"advancedfeatures="+this.amenc(DateTime.utc().toMillis().toString())+"&usrdmsid="+this.amenc(this.userdata.eid), "_blank");
        return
      }
    }
    this.helper.notify("No DMS Id found; please try logging in and out if you have just updated this info.")
  }

  gotoNormal() {
    if (this.userdata) {
      if (this.userdata.eid) {
        let alocation = this.userdata.roles.find(r => r.startsWith('location'));
        if (alocation) {
          alocation = alocation.substring(8);
        }
        window.open("https://osb.release.iq-motive.com/?"+(alocation?"preloc="+alocation+"&":"")+"usrdmsid="+this.amenc(this.userdata.eid), "_blank");
        return
      }
    }
    this.helper.notify("No DMS Id found; please try logging in and out if you have just updated this info.")
  }
}