import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

import { AppComponent } from './app.component';
import { SharedModule } from '../decorators/shared.module';
import { DatetimechooserDialog } from '../components/datetimechooser/datetimechooser.component';
import { FormsModule } from '@angular/forms';
import { Routes } from '@angular/router';
import { PathNotFoundComponent } from '../path-not-found/path-not-found.component';
import { HomeComponent } from '../pages/home/home.component';
import { HeaderComponent } from '../components/themeblocks/header/header.component';
import { ToolbarComponent } from '../components/themeblocks/toolbar/toolbar.component';
import { CrumbtrailComponent } from '../components/themeblocks/crumbtrail/crumbtrail.component';
import { AdditionaljobsComponent } from '../pages/additionaljobs/additionaljobs.component';
import { LocationsComponent } from '../pages/locations/locations.component';
import { SettingsComponent } from '../pages/settings/settings.component';
import { AppointmentsComponent } from '../pages/appointments/appointments.component';
import { ServicesComponent } from '../pages/services/services.component';
import { environment } from '../environments/environment';
import { AccountComponent } from '../pages/account/account.component';
import { LocationseditorComponent } from '../pages/locations/locationeditor/locationseditor.component';
import { ServicesEditDialog } from '../pages/services/servicesedit.component';
import { JobEditDialog } from '../pages/additionaljobs/jobeditor/jobsedit.component';
import { JobsComponent } from '../pages/additionaljobs/jobs/jobs.component';
import { FilterDialog } from '../pages/additionaljobs/jobeditor/filter.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AppointmentTableComponent } from '../pages/appointments/appointmenttable/appointmenttable.component';
import { AppointmentviewerComponent } from '../pages/appointments/appointmentviewer/appointmentviewer.component';

import { Settings as lSettings } from "luxon";

import { AuthService } from '../services/auth.service';
import { AppointmentService } from '../services/appointment.service';
import { AdditionalJobsService } from '../services/additionaljobs';
import { HelperService } from '../services/helper.service';
import { JSettingsService } from '../services/jsettings.service';
import { LocationsService } from '../services/locations.service';
import { ServicesService } from '../services/services.service';
import { UsersService } from '../services/users.service';
lSettings.defaultZoneName = 'UTC'

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent },
  { path: 'account', component: AccountComponent },
  { path: 'additionaljobs', component: AdditionaljobsComponent },
  { path: 'locations', component: LocationsComponent },
  { path: 'locations/editor', component: LocationseditorComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'appointments', component: AppointmentsComponent },
  { path: 'appointments/:id', component: AppointmentviewerComponent },
  { path: 'services', component: ServicesComponent },
  { path: '**', component: PathNotFoundComponent },
]

@NgModule({
  declarations: [
    AppComponent,
    PathNotFoundComponent,
    DatetimechooserDialog,
    HeaderComponent,
    ToolbarComponent,
    CrumbtrailComponent,
    HomeComponent,
    AdditionaljobsComponent,
    LocationsComponent,
    SettingsComponent,
    AppointmentsComponent,
    ServicesComponent,
    AccountComponent,
    LocationseditorComponent,
    JobsComponent,
    AppointmentTableComponent,
    AppointmentviewerComponent,

    // Dialogs
    ServicesEditDialog,
    JobEditDialog,
    FilterDialog
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxTippyModule,
    AuthModule.forRoot({
      config: {
        authWellknownEndpointUrl: environment.Keycloak.MetadataAddress,
        redirectUrl: window.location.origin,
        authority: 'https://auth.iq-motive.com/auth/realms/RELEASE/protocol/openid-connect/auth',
        clientId: 'osb-client',
        scope: 'openid profile email offline_access',
        responseType: 'code',
        triggerAuthorizationResultEvent: true,
        postLogoutRedirectUri: window.location.origin,
        startCheckSession: false,
        postLoginRoute: '',
        unauthorizedRoute: '/unauthorized',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        ignoreNonceAfterRefresh: true
      },
    }),
    IonicModule.forRoot({
      mode: "md",
      spinner: 'dots'
    }),
    RouterModule.forRoot(routes),
    SharedModule
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    AppointmentService,
    AdditionalJobsService,
    HelperService,
    JSettingsService,
    LocationsService,
    ServicesService,
    UsersService
  ]
})
export class AppModule {
  static injector: Injector;

  constructor(private injector: Injector) {
    AppModule.injector = injector;
  }
}
