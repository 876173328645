import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, interval, Subject, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { HelperService } from '../../services/helper.service';
import { JLocation, LocationsService } from '../../services/locations.service';
import { AppointmentTableComponent } from './appointmenttable/appointmenttable.component';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit, OnDestroy {

  @ViewChildren(AppointmentTableComponent) private allTables: QueryList<AppointmentTableComponent>;

  currentActive = localStorage.getItem('currentActiveAppt') ? localStorage.getItem('currentActiveAppt') : 'today'; 
  Today = DateTime.local().setZone('UTC').set({hour: 0, minute: 0, second: 0, millisecond: 0});
  Tomorrow = DateTime.local().setZone('UTC').set({hour: 23, minute: 59, second: 59, millisecond: 999})
  FromTime: DateTime | undefined = this.Today;
  TillTime: DateTime | undefined = this.Today.plus({days: 7});

  SearchTerm = ''

  CodeList: BehaviorSubject<string[]|undefined> = new BehaviorSubject<string[]|undefined>(undefined);
  SpecificLocation = [""];

  searchChangeSubject: Subject<any> = new Subject();
  Subscriptions: Subscription[] = []

  HiddenLocations = false;

  setActive(n) {
    localStorage.setItem('currentActiveAppt', n)
    this.currentActive = n;
  }

  constructor(private helper: HelperService, private locationService: LocationsService, public auth: AuthService, private router: Router) { }

  ngOnInit() {
    const minutes = timer(60000, 60000);
    this.Subscriptions.push(minutes.subscribe(x => this.tick(x)));
    this.Subscriptions.push(this.searchChangeSubject.pipe(debounce(() => interval(1000))).subscribe((a) => {
     
      this.allTables.forEach(i => {
        i.load();
      })
    }));
    
    this.setClock();
    this.loadState();
  }

  async loadState() {
    if (this.auth.AuthObject?.value?.roles.includes('OsbAllLocations')) {
      this.CodeList.next(await this.locationService.GetCodes());
    } else if (this.auth.AuthObject?.value?.locations) {
      let allowcodes = this.auth.AuthObject?.value?.locations.map(v => v.substring(8)).filter(l => l.length > 0);
      let loccodes = await this.locationService.GetCodes();
      let showcodes = loccodes.filter(c => allowcodes.includes(c));
      this.HiddenLocations = showcodes.length != loccodes.length;
      if (this.HiddenLocations) {
        this.SpecificLocation = showcodes;
      }
      if (showcodes.length == 0) {
        this.helper.notify('Not enough locations available!')
        this.router.navigateByUrl('/home')
      } else {
        this.CodeList.next(showcodes);
      }
    }
  }

  dropChange(e:any) {
    if (this.HiddenLocations) {
      if (e.detail.value.length == 0) {
        this.SpecificLocation = [this.CodeList.value[0]];
      } else {
        this.SpecificLocation = e.detail.value;
      }
    } else {
      if (e.detail.value.length == 0) {
        this.SpecificLocation = ['']
      } else if (e.detail.value.length > 1 && e.detail.value.includes('')) {
        this.SpecificLocation = e.detail.value.filter(v => v != '');
      } else {
        this.SpecificLocation = e.detail.value;
      }
    }
    this.onSearchChange();
    e.preventDefault()
  }

  onSearchChange() {
    this.searchChangeSubject.next();
  }

  ngOnDestroy() {
    this.Subscriptions.forEach(s => s.unsubscribe());
  }

  tick(x) {
    this.setClock();
  }

  setClock() {
    this.Today = DateTime.local().setZone('UTC').set({hour: 0, minute: 0, second: 0, millisecond: 0});
    this.Tomorrow = DateTime.local().setZone('UTC').set({hour: 23, minute: 59, second: 59, millisecond: 999})
  }

  clearTimes() {
    this.FromTime = undefined;
    this.TillTime = undefined;
    this.searchChangeSubject.next();
  }

  async setFrom() {
    if (!this.FromTime) {
      this.FromTime = this.Today;
      this.TillTime = this.Today.plus({days: 7});
    }
    this.FromTime = await this.helper.chooseDateTime(this.FromTime, true)
    if (this.TillTime && this.FromTime) {
      if (this.FromTime >= this.TillTime) {
        this.TillTime = this.FromTime.plus({days: 1});
      }
    }
    this.searchChangeSubject.next();
  }
  
  async setTill() {
    if (!this.TillTime) {
      this.FromTime = this.Today;
      this.TillTime = this.Today.plus({days: 7});
    }
    this.TillTime = await this.helper.chooseDateTime(this.TillTime, true)
    if (this.TillTime && this.FromTime) {
      if (this.TillTime <= this.FromTime) {
        this.FromTime = this.TillTime.plus({days: -1});
      }
    }
    this.searchChangeSubject.next();
  }

}
