import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, timer } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    AuthObject: BehaviorSubject<UserData|undefined> = new BehaviorSubject<UserData|undefined>(undefined);

    constructor
        (
            private oidcSecurityService: OidcSecurityService,
            private helper: HelperService
        ) {
            console.log("INIT Auth Service");
            this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
                this.handleAuth(isAuthenticated, userData, accessToken, idToken);
            });
            timer(1000, 1000).subscribe(async (v) => {
                let t = await oidcSecurityService.getAccessToken().toPromise()
                if (t) {
                    localStorage.setItem("KCTOKEN", t)
                } else {
                    localStorage.removeItem("KCTOKEN")
                }
            })
    }

    handleAuth(isAuthenticated, userData, accessToken, idToken) {
        console.log('handleTriggered')
        const tokenSegments = (accessToken+"").split('.');
        if (isAuthenticated && tokenSegments.length == 3) {
            console.log("Authenticated")
            const tokenData = JSON.parse(atob(tokenSegments[1]))
            console.log(tokenData);
            if (tokenData.upn && tokenData.realm_access?.roles && tokenData.eid && tokenData.name) {
                const roleList: string[] = (tokenData.realm_access.roles as string[]).filter(r => !r.startsWith('Vhc') && !r.startsWith('Iqp'))
                let userdata: UserData = {
                    email: tokenData.upn,
                    roles: roleList.filter(r => !r.startsWith('Location')),
                    eid: tokenData.eid,
                    name: tokenData.name,
                    locations: roleList.filter(r => r.startsWith('Location'))
                };
                console.log(userdata);
                if (userdata.roles.includes("OsbCanOpen")) {
                    this.AuthObject.next(userdata);
                    localStorage.setItem("KCTOKEN", accessToken)
                    return;
                } else {
                    this.helper.notify("Unauthorised");
                }
            }
        }
        localStorage.removeItem("KCTOKEN")
        console.log("No token")
        this.AuthObject.next(undefined);
    }

    setToken() {

    }

    refresh() {
        this.helper.notify("Refreshing")
        this.login();
    }

    login() {
        this.oidcSecurityService.authorize();
    }

    logout() {
        this.oidcSecurityService.logoff();
    }
}

export interface UserData {
    roles: string[],
    eid: string,
    name: string,
    email: string,
    locations: string[]
}