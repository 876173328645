import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';
import { AdditionalJob } from './additionaljobs';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http : HttpClient) { }

  /**
   * Get
   */
  public Get() {
    return this.http.get<JLocation[]>(environment.ApiURL+'/location', {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<JLocation[]>()
  }
  
  public GetShort() {
    return this.http.get<JLocationShort[]>(environment.ApiURL+'/location/locationshort', {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<JLocationShort[]>()
  }

  CodeListAge: number = 100;
  CodeList: string[] = [];
  
  public async GetCodes(): Promise<string[]> {
    console.log('Serve the codes');
    if (this.CodeListAge < DateTime.local().toSeconds()-300) {
      this.CodeListAge = DateTime.local().toSeconds();
      console.log('Fetch the from the server');
      this.CodeList = await this.http.get<string[]>(environment.ApiURL+'/location/locationcodes',{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<string[]>()
    } else {
      console.log('Serve from cache');
    }
    return this.CodeList.sort((a,b)=> (a<b? -1 : 1));
  }

  /**
   * Get
   */
  public GetOne(id:number) {
    return this.http.get<JLocation>(environment.ApiURL+'/location/'+id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<JLocation>()
  }

  /**
   * Post
   */
  public Post(location: JLocation) {
    location.additionalJobs = null
    return this.http.post<JLocation>(
      environment.ApiURL+'/location',
      location,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<JLocation>()
  }

  /**
   * Put
   */
  public Put(location: JLocation) {
    if (location.uploadNewPic != true) {
      location.photo = "";
    }
    location.additionalJobs = null
    return this.http.put<any>(
      environment.ApiURL+'/location',
      location,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<any>()
  }

  /**
   * Delete
   */
  public Delete(id:number) {
    return this.http.delete<any>(environment.ApiURL+'/location/delete/'+id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<any>()
  }
}

export interface JLocation {
  id?: number;
  geo: string;
  brands: string;
  photo: string;
  locationCode: string;
  locationName: string;
  locationNameAr: string;
  apiUrlAvailability: string;
  apiUrlFinalStep: string;
  apiUrlDelete: string;
  locationAddress: string;
  locationAddressAr: string;
  locationMaps: string;
  additionalJobs: AdditionalJob[]|null;
  uploadNewPic?:boolean;
  timeCap:number;
  slotMinutes: number;
  isPromoted: boolean;
  isOn: boolean;
  isPinOnly: boolean;
}

export interface JLocationShort {
  isOn?: boolean,
  isPromoted: boolean,
  id: number,
  locationName: string,
  locationCode: string
}