import { Component, Input, OnInit } from '@angular/core';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { Appointment, AppointmentService, ListAppointment } from '../../../services/appointment.service';
import { HelperService } from '../../../services/helper.service';
import { PaginationModel } from '../../../services/interfaces';

@Component({
  selector: 'app-appointmenttable',
  templateUrl: './appointmenttable.component.html',
  styleUrls: ['./appointmenttable.component.scss'],
})
export class AppointmentTableComponent implements OnInit {

  @Input('apptType') apptType = 'open';
  @Input('searchTerm') searchTerm = '';
  @Input('locationTerm') locationTerm = [''];
  @Input('fromTime') fromTime: DateTime | undefined = undefined;
  @Input('tillTime') tillTime: DateTime | undefined = undefined;

  dateFormatOptions: DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }
  from = 0;
  amount = 15;
  total = 0;
  apptList: BehaviorSubject<ListAppointment[] | undefined> = new BehaviorSubject<ListAppointment[] | undefined>(undefined);

  constructor(private helper: HelperService, private apptService: AppointmentService) { }

  ngOnInit() {
    this.load();
  }

  next() {
    this.apptList.next(undefined);
    this.getData(this.from + this.amount, this.amount, this.searchTerm.toLowerCase());
  }

  prev() {
    this.apptList.next(undefined);
    this.getData(this.from - this.amount, this.amount, this.searchTerm.toLowerCase());
  }

  public min(a, b) {
    return Math.min(a, b)
  }

  async load() {
    console.log('Reloading ' + this.apptType + ' table')
    this.from = 0;
    this.apptList.next(undefined);
    this.getData(this.from, this.amount, this.searchTerm.toLowerCase());
  }

  async getData(from, amount, search) {
    let v: PaginationModel<ListAppointment[]> | undefined = undefined;
    const joinedlocation = this.locationTerm.join(',')
    console.log(joinedlocation);
    if (this.apptType == 'today') {
      v = await this.apptService.GetAll(from, amount, search, '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    } else if (this.apptType == 'open') {
      v = await this.apptService.GetOpen(from, amount, search, '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    } else if (this.apptType == 'completed') {
      v = await this.apptService.GetCompleted(from, amount, search, '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    } else if (this.apptType == 'noshow') {
      v = await this.apptService.GetNowshow(from, amount, search, '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    } else if (this.apptType == 'cancelled') {
      v = await this.apptService.GetCancelled(from, amount, search, '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    } else {
      v = await this.apptService.GetAll(from, amount, search, '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    }
    if (v) {
      this.handleData(v);
    } else {
      this.helper.notify("Could not load data", "danger")
    }
  }

  handleData(v: PaginationModel<ListAppointment[]>) {
    this.apptList.next(v.data)
    this.from = v.from;
    this.amount = v.amount;
    this.total = v.total;
  }

  async print() {
    let tableStyle ='<style>tr:nth-child(even) {background-color: #eaeaea;}</style>'
    const joinedlocation = this.locationTerm.join(',')
    console.log("joinedlocation", this.locationTerm)
    const data = await this.apptService.GetAll(0, 150, '', '-', (joinedlocation === '' ? '-' : joinedlocation), (this.fromTime ? Math.floor(this.fromTime.toSeconds()) : 0), (this.tillTime ? Math.floor(this.tillTime.toSeconds()) : 0), '-')
    let html = `
    <h1>Appointments - ${this.apptType[0].toUpperCase() + this.apptType.slice(1)} Table</h1>`;
    if (this.locationTerm.length == 1 && this.locationTerm[0] != '') {
      html += ` <p><strong>Location Code: </strong>${this.locationTerm[0]}</p>`
    }
    html +=
      `
    <p><strong>From: </strong>${this.fromTime?.toLocaleString()}</p>
    <p><strong>Till: </strong>${this.tillTime?.toLocaleString()}</p>
      <table style="width:100%">
        <tr>
        <th>ID</th>
        <th>Customer name</th>
        <th>Customer phone</th>
        <th>License Plate</th>`;
    if (this.locationTerm.includes('') || this.locationTerm.length > 1) {
      console.log('js')
      html += `<th>Location Code</th>`
    }
    html +=
      `
        <th>Arriving</th>
        <th>Confirm text</th>
        <th>Status</th>
        </tr>`;
    for (let i = 0; i < data.data.length; i++) {
      html += `<tr>
            <td> ${data.data[i].id.toString().length > 10 ? data.data[i].id.toString().slice(0, -3) : data.data[i].id.toString()}</td>
            <td> ${data.data[i].customerName}</td>
            <td> ${data.data[i].phoneNumber}</td>
            <td> ${data.data[i].licensePlate ? data.data[i].licensePlate : '-'}</td>`;
      if (this.locationTerm.includes('') || this.locationTerm.length > 1) {
        html += `<td> ${data.data[i].locationCode}</td>`
      };
      html +=
        `
            <td> ${data.data[i].arrivalTime ? (new Date((data.data[i].arrivalTime) * 1000)).toLocaleString("en-us", this.dateFormatOptions) : '-'}</td>
            <td>${data.data[i].creationTextSent ? (new Date((data.data[i].creationTextSent) * 1000)).toLocaleString("en-us", this.dateFormatOptions) : '-'}</td>
            <td> ${data.data[i].appointmentStatus}</td>
            </tr>
         `
    }

    html += `
        </table>
    `;
    let newWin = window.open("");
    newWin.document.head.innerHTML = tableStyle;
    newWin.document.body.innerHTML = html;
    newWin.print();
  }
}
