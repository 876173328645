import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { AdditionalJob, AdditionalJobsService } from '../../../services/additionaljobs';
import { HelperService } from '../../../services/helper.service';
import { JobEditDialog } from '../jobeditor/jobsedit.component';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {

  @Input('isconcern') isconcern = false;
  jobsList: BehaviorSubject<AdditionalJob[]|undefined> = new BehaviorSubject<AdditionalJob[]|undefined>(undefined);
  constructor(public jobsService: AdditionalJobsService, private helper: HelperService, private modalController: ModalController) { }

  ngOnInit() {
    this.getData();
  }

  async newJob() {
    let res = await this.helper.createAndOpenModal(JobEditDialog,{isconcern: this.isconcern});
    if (!res || !res.data) {
      return;
    } else if (res.data.wasSaved) {
      this.jobsList.next(undefined);
      this.jobsService.Post(res.data.data).then(() => {
        this.helper.notify("Job was saved", "success");
        this.getData();
      }).catch(e => {
        this.helper.notify(e.error.message ? e.error.message : "Could not save the job", "danger");
        console.log(e);
        this.getData();
      })
    }
  }

  async editJob(job: AdditionalJob) {
    let res = await this.helper.createAndOpenModal(JobEditDialog, {isconcern: this.isconcern, formdata: job});
    if (!res || !res.data) {
      return;
    } else if (res.data.wantsDelete && job.id) {
      this.jobsList.next(undefined);
      this.jobsService.Delete(job.id).then(() => {
        this.helper.notify("Job was deleted", "success");
        this.jobsList.next(undefined);
        this.getData();
      }).catch((e) => {
        console.log(e);
        this.helper.notify(e.error.message ? e.error.message : "Could not be deleted", "danger")
        this.jobsList.next(undefined);
        this.getData();
      })
    } else if (res.data.wasSaved) {
      this.jobsList.next(undefined);
      this.jobsService.Put(res.data.data).then(() => {
        this.helper.notify("Job was saved", "success");
        this.jobsList.next(undefined);
        this.getData();
        this.ngOnInit();
      }).catch(e => {
        this.helper.notify(e.error.message ? e.error.message : "Could not save the Job", "danger");
        console.log(e);
        this.jobsList.next(undefined);
        this.getData();
      })
    }
  }

  getData() {
    if (this.isconcern) {
      this.jobsService.GetConcerns().then(v => {
        this.jobsList.next(v)
      });
    } else {
      this.jobsService.GetJobs().then(v => {
        this.jobsList.next(v)
      });
    }
  }

}
