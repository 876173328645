import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Appointment, AppointmentService } from '../../../services/appointment.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-appointmentviewer',
  templateUrl: './appointmentviewer.component.html',
  styleUrls: ['./appointmentviewer.component.scss'],
})
export class AppointmentviewerComponent implements OnInit {

  id=0
  _appointment: BehaviorSubject<Appointment|undefined> = new BehaviorSubject<Appointment|undefined>(undefined);

  constructor(private router: Router, private route: ActivatedRoute, public appointmentService: AppointmentService, private helper: HelperService, private loadingController: LoadingController) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params, Object.keys(params));
      if (Object.keys(params).includes('id')) {
        this.id = Number.parseInt(params.id);
        if (!Number.isNaN(this.id)) {
          this.loadData();
          return;
        }
      }
      this.router.navigateByUrl('/appointments')
    });
  }

  async save() {
    const loading = await this.loadingController.create({
      message: 'Uploading appointment',
      spinner: 'bubbles'
    });
    await loading.present();
    let result = await this.appointmentService.Put(this._appointment.value)
    loading.dismiss();
    console.log(result);
  }

  loadData() {
    this.appointmentService.GetOne(this.id).then(v => {
      this._appointment.next(v);
    }).catch(e => {
      console.log(e);
      this.helper.notify("Could not load the appointment", 'danger');
    })
  }

}
