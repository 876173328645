import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';
import { AdditionalJob } from './additionaljobs';
import { DateTime } from 'luxon';
import { PaginationModel } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http: HttpClient) { }

  /**
   * Get
   */
  
  public Get(from: number, amount: number, search: string) {
    return this.http.get<PaginationModel<JService[]>>(environment.ApiURL + `/service?from=${from}&amount=${amount}&search=${search}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<JService[]>>()
  }
 
  /**
   * Get
   */
  public GetOne(id: number) {
    return this.http.get<JService>(environment.ApiURL + '/service/' + id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<JService>()
  }

  /**
   * Post
   */
   public Post(service: JService) {
    return this.http.post<JService>(
      environment.ApiURL+'/service',
      service,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<JService>()
  }

  /**
   * Put
   */
  public Put(service: JService) {
    return this.http.put<any>(
      environment.ApiURL+'/service',
      service,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<any>()
  }

  /**
   * Delete
   */
  public Delete(id: number) {
    return this.http.delete<any>(environment.ApiURL + '/service/delete/' + id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<any>()
  }
}

export interface JService {
  id: number|undefined;
  carLine: string;
  kms: number;
  service_eng: string;
  service_arb: string;
  realCode: string;
  realPrice: string;
  specification: string;
  realDuration: number;
}