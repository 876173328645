import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'jjj-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input('crumb') crumb = [['Page subtitle']];
  @Input('title') pageTitle = "Page title";
  @Input('enableadd') hasAdd = false;
  @Input('enablemenu') hasMenu = false;

  @Input('enablesave') hasSaveOnly = false;

  @Output('add') onadd = new EventEmitter<void>();
  @Output('save') onsave = new EventEmitter<void>();
  @Output('back') onback = new EventEmitter<void>();
  @Output('delete') ondelete = new EventEmitter<void>();

  constructor(public helper: HelperService) { }

  ngOnInit() {
  }

  onAdd() {
    this.onadd.emit();
  }
  
  onSave() {
    this.onsave.emit();
  }
  
  onBack() {
    this.onback.emit();
  }

  @ConfirmDelete()
  onDelete() {
    this.ondelete.emit();
  }

}
