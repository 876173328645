import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http : HttpClient) { }

  /**
   * Get
   */
  public Get() {
    return this.http.get<User[]>(environment.ApiURL+'/users').toPromise<User[]>()
  }

  /**
   * Get
   */
  public GetOne(id:number) {
    return this.http.get<User>(environment.ApiURL+'/users/'+id).toPromise<User>()
  }

  /**
   * Post
   */
  public Post(user: User) {
    return this.http.post<User>(
      environment.ApiURL+'/users/register',
      user
    ).toPromise<User>()
  }

  /**
   * Put
   */
  public Put(user: User) {
    return this.http.put<any>(
      environment.ApiURL+'/users/update',
      user
    ).toPromise<any>()
  }

  /**
   * Delete
   */
  public Delete(id:number) {
    return this.http.delete<any>(environment.ApiURL+'/users/delete/'+id).toPromise<any>()
  }
}

export interface User {
  id?: number;
  firstName: string,
  lastName: string,
  username: string,
  isAdmin: boolean,
  dmsId: string,
  defaultLocationCode: string,
  password?: string,
  role: number,
  fromProfileButton?:boolean
}