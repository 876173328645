import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultLocationImage } from '../../../app/defaultlocimg';
import { HelperService } from '../../../services/helper.service';
import { JLocation, LocationsService } from '../../../services/locations.service';

@Component({
  selector: 'app-locationseditor',
  templateUrl: './locationseditor.component.html',
  styleUrls: ['./locationseditor.component.scss'],
})
export class LocationseditorComponent implements OnInit {

  isUpdate = false
  id = 0
  timeCapMinute= 0
  timeCapHour= 0
  location: JLocation|undefined = undefined

  constructor(private router: Router, public locationsService: LocationsService, private helper: HelperService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.route.params.subscribe(params => {
      console.log(params, Object.keys(params));
      if (Object.keys(params).includes('id')) {
        this.id = params.id;
        this.loadData();
      } else {
        this.location = {
          additionalJobs: [],
          apiUrlAvailability: "",
          apiUrlDelete: "",
          apiUrlFinalStep: "",
          brands: "",
          geo: "",
          locationAddress: "",
          locationAddressAr: "",
          locationCode: "",
          locationMaps: "",
          locationName: "",
          locationNameAr: "",
          photo: DefaultLocationImage,
          timeCap: 1080,
          slotMinutes: 15,
          isOn: false,
          isPromoted: false,
          id: undefined,
          isPinOnly: false
        }
      }
    });
  }

  uploadPicture(event) {
    if (event.target.files == undefined){
      this.helper.notify("Please upload a file", 'warning')
      return;
    }
    if (event.target.files[0] == undefined){
      this.helper.notify("Please upload a file", 'warning')
      return;
    }
    if (event.target.files[0].type == undefined){
      this.helper.notify("Please upload a file", 'warning')
      return;
    }
    if (!event.target.files[0].type.includes("image")){
      this.helper.notify("Please upload an image", 'warning')
      return;
    }
    if (event.target.files[0].size == undefined){
      this.helper.notify("Please upload an image", 'warning')
      return;
    }
    if (event.target.files[0].size / 1024 > 100){
      this.helper.notify("The image should be smaller then 100KB", 'warning')
      return;
    }
    let file = event.target.files[0];
    console.log(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      if (reader.result && this.location) {
        this.location.photo = reader.result.toString();
        this.location.uploadNewPic = true;
        this.helper.notify("Loaded image successfully", 'success');
      }
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.helper.notify("Could not load image", 'warning')
    };
  }

  async loadData() {
    this.locationsService.GetOne(this.id).then(v => {
      console.log(v);
      this.location = v;
      if (!this.location.timeCap) {
        this.location.timeCap = 0;
      }
      this.timeCapHour = Math.floor(this.location.timeCap / 60);
      this.timeCapMinute = this.location.timeCap - (this.timeCapHour * 60)

      this.isUpdate = true;
    }).catch(e => {
      this.helper.notify('Could not load location', 'danger')
    })
  }

  onSave() {
    if (this.location) {
      this.location.timeCap = this.timeCapMinute + (this.timeCapHour * 60)
      if (this.isUpdate) {
        const oldpic = this.location.photo;
        this.locationsService.Put(this.location).then(()=>{
          this.helper.notify("Location was saved", 'success');
          if (this.location) this.location.photo = oldpic;
        }).catch(e => {
          this.helper.notify(e.error.message?e.error.message:"Could not save the location", 'danger');
          console.log(e);
          if (this.location) this.location.photo = oldpic;
        })
      } else {
        this.locationsService.Post(this.location).then((v)=>{
          this.helper.notify("Location was saved", 'success');
          this.location = v;
          this.isUpdate = true;
        }).catch(e => {
          this.helper.notify(e.error.message?e.error.message:"Could not save the location", 'danger');
          console.log(e);
        })
      }
    }
  }

  onBack() {
    this.router.navigate(['locations'])
  }

  onDelete() {
    if (this.location?.id) {
      this.locationsService.Delete(this.location.id).then(()=>{
        this.helper.notify("Location was deleted", 'success');
        this.router.navigate(['locations'])
      }).catch((e)=>{
        console.log(e);
        this.helper.notify(e.error.message?e.error.message:"Could not be deleted", 'danger')
      })
    }
  }
}
