import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../environments/environment';
import { PaginationModel } from './interfaces';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(private http : HttpClient) { }

  /**
   * Get
   */
  public Get(from: number, amount: number, search: string, includecancelled: boolean, SpecificSA:string, startDate:number, endDate:number, specificLocation:string, sort: string, noshow=false, dmsId="-") {
    return this.http.get<PaginationModel<ListAppointment[]>>(environment.ApiURL+`/appointment?from=${from}&amount=${amount}&includecancelled=${includecancelled}&search=${search}&specificLocation=${specificLocation}&startDate=${startDate}&endDate=${endDate}&specificSA=${SpecificSA}&sort=${sort}&noshow=${noshow}&dmsId=${dmsId}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<ListAppointment[]>>()
  }
  
  public GetAll(from: number, amount: number, search: string, SpecificSA: string, specificLocation:string, startDate:number, endDate:number, sort: string, dmsId="-") {
    return this.http.get<PaginationModel<ListAppointment[]>>(environment.ApiURL+`/appointment/getall?from=${from}&amount=${amount}&search=${search}&specificLocation=${specificLocation}&startDate=${startDate}&endDate=${endDate}&specificSA=${SpecificSA}&sort=${sort}&dmsId=${dmsId}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<ListAppointment[]>>()
  }

  public GetNowshow(from: number, amount: number, search: string, SpecificSA: string, specificLocation:string, startDate:number, endDate:number, sort: string, dmsId="-") {
    return this.http.get<PaginationModel<ListAppointment[]>>(environment.ApiURL+`/appointment/getnowshow?from=${from}&amount=${amount}&search=${search}&specificLocation=${specificLocation}&startDate=${startDate}&endDate=${endDate}&specificSA=${SpecificSA}&sort=${sort}&dmsId=${dmsId}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<ListAppointment[]>>()
  }

  public GetOpen(from: number, amount: number, search: string, SpecificSA: string, specificLocation:string, startDate:number, endDate:number, sort: string, dmsId="-") {
    return this.http.get<PaginationModel<ListAppointment[]>>(environment.ApiURL+`/appointment/getopen?from=${from}&amount=${amount}&search=${search}&specificLocation=${specificLocation}&startDate=${startDate}&endDate=${endDate}&specificSA=${SpecificSA}&sort=${sort}&dmsId=${dmsId}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<ListAppointment[]>>()
  }

  public GetCancelled(from: number, amount: number, search: string, SpecificSA: string, specificLocation:string, startDate:number, endDate:number, sort: string, dmsId="-") {
    return this.http.get<PaginationModel<ListAppointment[]>>(environment.ApiURL+`/appointment/getcancelled?from=${from}&amount=${amount}&search=${search}&specificLocation=${specificLocation}&startDate=${startDate}&endDate=${endDate}&specificSA=${SpecificSA}&sort=${sort}&dmsId=${dmsId}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<ListAppointment[]>>()
  }

  public GetCompleted(from: number, amount: number, search: string, SpecificSA: string, specificLocation:string, startDate:number, endDate:number, sort: string, dmsId="-") {
    return this.http.get<PaginationModel<ListAppointment[]>>(environment.ApiURL+`/appointment/getcompleted?from=${from}&amount=${amount}&search=${search}&specificLocation=${specificLocation}&startDate=${startDate}&endDate=${endDate}&specificSA=${SpecificSA}&sort=${sort}&dmsId=${dmsId}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<PaginationModel<ListAppointment[]>>()
  }

  /**
   * Get
   */
  public GetOne(id:number) {
    return this.http.get<Appointment>(environment.ApiURL+'/appointment/'+id,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<Appointment>()
  }
  
  public GetOverview() {
    return this.http.get<Map<string,number>>(environment.ApiURL+'/appointment/overview',{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<Map<string,number>>()
  }
  
  public GetPerDate() {
    return this.http.get<Map<number,number>>(environment.ApiURL+'/appointment/perdate',{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<Map<number,number>>()
  }

  /**
   * Put
   */
  public Put(appointment: Appointment) {
    return this.http.put<any>(
      environment.ApiURL+'/appointment',
      appointment,
      {headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}
    ).toPromise<any>()
  }

  public DeleteAppointment(id:number) {
    const _time = DateTime.utc().toSeconds().toString();
    const left = (Number.parseInt(_time.substr(0,5))*2).toString();
    const right = (Number.parseInt(_time.substring(5))*2).toString();
    return this.http.get<AppointmentDeleteModel>(environment.ApiURL+`/customer/deleteappointment?idVal=${left}.${id*2}.${right}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<AppointmentDeleteModel>()
  }
  
  public CompleteAppointment(id:number) {
    const _time = DateTime.utc().toSeconds().toString();
    const left = (Number.parseInt(_time.substr(0,5))*2).toString();
    const right = (Number.parseInt(_time.substring(5))*2).toString();
    return this.http.get<AppointmentCompleteModel>(environment.ApiURL+`/customer/completeappointment?idVal=${left}.${id*2}.${right}`,{headers:{"Authorization" : "Bearer " + localStorage.getItem("KCTOKEN")}}).toPromise<AppointmentCompleteModel>()
  }
}

export interface AppointmentDeleteModel
{
  error?: string
}

export interface AppointmentCompleteModel
{
  error?: string
}

export interface Appointment {
  id?: number;
  cancelDate?:number;
  phoneNumber: string;
  vin: string;
  brand: string;
  model: string;
  licensePlate: string;
  customerName: string;
  arrivalTime: number;
  handoverTime: number;
  receptionist: string;
  locationCode: string;
  creationTextSent: number;
  reminderTextSent: number;
  appointmentCancelled: boolean;
  appointmentCompleted: boolean;
  appointmentCompletedLate: boolean;
  jobs: Job[];
  fromCustomer: boolean;
  withUser: string;
  hhaNote: string;
  seenFolder:boolean;

  callBrochure?: boolean;
  callFollowUp?: boolean;
  callNoShow?: boolean;
  callOther?: boolean;

  remark?: string;
}

export interface Job {
  id: number;
  operationCode: string;
  duration: number;
  name: string;
  customerTooltip: string;
  price: string;
}

export interface ListAppointment {
  id?: number;
  licensePlate: string;
  customerName: string;
  arrivalTime: number;
  locationCode: string;
  creationTextSent: number;
  appointmentStatus: string;
}