import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { JService, ServicesService } from '../../services/services.service';
import { debounce } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { ServicesEditDialog } from './servicesedit.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {

  from = 0;
  amount = 15;
  total = 0;
  searchValue: string = '';
  subScriptions: Subscription[] = []
  servicesList: BehaviorSubject<JService[]|undefined> = new BehaviorSubject<JService[]|undefined>(undefined);
  searchChangeSubject: Subject<any> = new Subject();
  constructor(public servicesService: ServicesService, private helper: HelperService, private modalController: ModalController) { }

  ngOnInit() {
    this.getData(this.from, this.amount, this.searchValue.toLowerCase());
    this.subScriptions.push(this.searchChangeSubject.pipe(debounce(() => interval(1000))).subscribe((a) => {
      this.from = 0;
      this.getData(this.from, this.amount, this.searchValue.toLowerCase());
      console.log('searchValue', this.searchValue.toLowerCase())
    }));
  }

  async newServices() {
    let res = await this.helper.createAndOpenModal(ServicesEditDialog,{});
    
    if (res == undefined) {
      return;
    } else if (res.data.wasSaved) {
      this.servicesList.next(undefined);
      this.servicesService.Post(res.data.data).then(() => {
        this.helper.notify("Services was saved", "success");
        this.getData(this.from, this.amount, this.searchValue.toLowerCase());
      }).catch(e => {
        this.helper.notify(e.error.message ? e.error.message : "Could not save the service", "danger");
        console.log(e);
        this.getData(this.from, this.amount, this.searchValue.toLowerCase());
      })
    }
  }

  next() {
    this.servicesList.next(undefined);
    this.getData(this.from + this.amount, this.amount, this.searchValue.toLowerCase());
  }

  prev() {
    this.servicesList.next(undefined);
    this.getData(this.from - this.amount, this.amount, this.searchValue.toLowerCase());
  }

  public min(a, b) {
    return Math.min(a, b)
  }

  onSearchChange() {
    this.searchChangeSubject.next();
  }

  async editServices(jService: JService) {
    let res = await this.helper.createAndOpenModal(ServicesEditDialog, {formdata: jService});
    if (res == undefined) {
      return;
    } else if (res.data.wantsDelete && jService.id) {
      this.servicesList.next(undefined);
      this.servicesService.Delete(jService.id).then(() => {
        this.helper.notify("Service was deleted", "success");
        this.servicesList.next(undefined);
        this.getData(this.from, this.amount, this.searchValue.toLowerCase());
      }).catch((e) => {
        console.log(e);
        this.helper.notify(e.error.message ? e.error.message : "Could not be deleted", "danger")
        this.servicesList.next(undefined);
        this.getData(this.from, this.amount, this.searchValue.toLowerCase());
      })
    } else if (res.data.wasSaved) {
      this.servicesList.next(undefined);
      this.servicesService.Put(res.data.data).then(() => {
        this.helper.notify("Service was saved", "success");
        this.servicesList.next(undefined);
        this.getData(this.from, this.amount, this.searchValue.toLowerCase());
        this.ngOnInit();
      }).catch(e => {
        this.helper.notify(e.error.message ? e.error.message : "Could not save the Service", "danger");
        console.log(e);
        this.servicesList.next(undefined);
        this.getData(this.from, this.amount, this.searchValue.toLowerCase());
      })
    }
  }

  getData(from, to, search) {
    this.servicesService.Get(from, to, search).then(v => {
      this.servicesList.next(v.data)
      this.from = v.from;
      this.amount = v.amount;
      this.total = v.total;
    });
  }

}
