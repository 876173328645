import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { ExternalConfigState, JSetting, JSettingsService } from '../../services/jsettings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  JSettings: BehaviorSubject<JSetting|undefined>                  = new BehaviorSubject<JSetting|undefined>(undefined);
  ExternalConfigs: ExternalConfigState;
  ExternalArrayId=0;
  showExternalConfig=false;
  constructor(private settingService: JSettingsService, private helper: HelperService) { }

  ngOnInit() {
    this.settingService.Get().then(v => {
      this.JSettings.next(v);
      this.settingService.getExternal().then(x=>{
        this.ExternalConfigs=x
        this.ExternalConfigs.externalList.forEach(e=>{
          let dat= []
          Object.entries(JSON.parse(e.data as string)).forEach(([key, value])=>{
            dat.push([value['type'], value['label'], value['value'], key])
          })
          e.data = dat
        })
        this.switchExternal(this.ExternalConfigs.selectedExternal)
      })
    }, r => {
      this.helper.notify("Could not load data", "danger")
    })

    this.settingService.getExternal().then(x=>{
      this.ExternalConfigs=x
      this.ExternalConfigs.externalList.forEach(e=>{
        e.data=JSON.parse(e.data as string)
      })
      
    })
  }

  switchExternal(externalId: number){
    this.ExternalConfigs.selectedExternal = externalId
    this.ExternalArrayId                  = this.ExternalConfigs.externalList.findIndex(e=> e.id === this.ExternalConfigs.selectedExternal)
    this.showExternalConfig               = Object.keys(this.ExternalConfigs.externalList.find(e=> e.id === this.ExternalConfigs.selectedExternal).data).length>0
  }

  uploadPicture(event) {
    if (event.target.files == undefined){
      this.helper.notify("Please upload a file", "warning")
      return;
    }
    if (event.target.files[0] == undefined){
      this.helper.notify("Please upload a file", "warning")
      return;
    }
    if (event.target.files[0].type == undefined){
      this.helper.notify("Please upload a file", "warning")
      return;
    }
    if (!event.target.files[0].type.includes("image")){
      this.helper.notify("Please upload an image", "warning")
      return;
    }
    if (event.target.files[0].size == undefined){
      this.helper.notify("Please upload an image", "warning")
      return;
    }
    if (event.target.files[0].size / 1024 > 100){
      this.helper.notify("The image should be smaller then 100KB", "warning")
      return;
    }
    let file = event.target.files[0];
    console.log(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      if (reader.result && this.JSettings.value) {
        this.JSettings.value.companyLogo = reader.result.toString();
        this.JSettings.value.uploadNewPic = true;
      }
      this.helper.notify("Loaded image successfully", "success");
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.helper.notify("Could not load image", "danger")
    };
  }

  save() {
    let setting = this.JSettings.value
    this.showExternalConfig=false;
    if (setting) {
      if(typeof(setting.useSpreadPayment)=='boolean')
        setting.useSpreadPayment= setting.useSpreadPayment?1:0;
      this.JSettings.next(undefined);
      this.settingService.Put(setting).then(async ()=>{
        await this.settingService.switchExternal(this.ExternalConfigs).then(()=>{
        }).catch((e)=>{
          this.helper.notify("External configurations were not saved", "danger")
        })
        this.helper.notify("Settings were saved", "success")
      }).catch((e)=>{
        console.log(e);
        this.helper.notify("Settings were not saved", "danger")
      }).finally(()=>{
        this.ngOnInit();
      })
    }
  }

}
