import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AlertController, AlertInput, LoadingController, ModalController, PickerController, ToastController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';
import { DatetimechooserDialog } from '../components/datetimechooser/datetimechooser.component';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor
    (
      private toastController: ToastController,
      private alertController: AlertController,
      private modalController: ModalController,
      private loadingController: LoadingController,
      private _location: Location,
      private pickerController: PickerController,
    ) {
    console.log("INIT H Service");
  }
  
  
  public back(): void {
    this._location.back()
  }
  
  public goto(url:string): void {
    this._location.back()
  }

  async notify(msg, color: PredefinedColors = 'primary', duration = 2000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: duration,
      color: color
    });
    toast.present();
  }

  public getUUID() {
    return uuidv4();
  }

  public async promptYesNo(title: string, header: string, okHandler: Function, okText: string = "Ok", cancelText: string = "Cancel"): Promise<boolean> {
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: () => { okHandler(); }
        }
      ]
    });

    await alert.present();
    let ans = await alert.onDidDismiss();
    return !(ans.role == 'backdrop' || ans.role == 'cancel')
  }

  public async getLoader() {
    return this.loadingController.create({
      spinner: null,
      duration: 0,
      mode: 'ios',
      message: 'Loading, please wait...',
      backdropDismiss: true
    });
  }

  public async chooseDateTime(datetime: DateTime, dateOnly=false) {
    console.log(datetime.toFormat("dd MMMM yyyy"))
    const modal = await this.modalController.create({
      component: DatetimechooserDialog,
      mode: 'ios',
      cssClass: 'doubled-modal auto-height',
      componentProps: {
        datevalue: datetime,
        dateOnly: dateOnly
      },
    });

    await modal.present();
    let {data} = await modal.onDidDismiss();
    if (data) {
      if (data.date) {
        return data.date;
      }
    }
    return datetime;
  }

  public async promptInput(title: string, header: string, inputValue = '', okHandler: Function, inputPlaceholder = "Please answer this question", inputType: TextFieldTypes | 'checkbox' | 'radio' | 'textarea' = 'text', okText: string = "Ok", cancelText: string = "Cancel"): Promise<boolean> {
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      inputs: [
        {
          name: 'theinput',
          attributes: {
            autoComplete: "off"
          },
          type: inputType,
          value: inputValue,
          placeholder: inputPlaceholder,
        }
      ],
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: (q) => { okHandler(q.theinput); }
        }
      ]
    });

    await alert.present();
    let ans = await alert.onDidDismiss();
    return !(ans.role == 'backdrop' || ans.role == 'cancel')
  }

  public async createAndOpenAlert(title: string, header: string, okHandler: Function, inputs:AlertInput[], okText: string = "Ok", cancelText: string = "Cancel", cssClass = ""): Promise<boolean>
  {
    const alert = await this.alertController.create({
      header: title,
      subHeader: header,
      inputs: inputs,
      cssClass: cssClass,
      buttons: [
        {
          text: cancelText,
          role: 'cancel'
        }, {
          text: okText,
          handler: (q) => { okHandler(q); }
        }
      ]
    });

    await alert.present();
    let ans = await alert.onDidDismiss();
    return !(ans.role == 'backdrop' || ans.role == 'cancel')
  }

  public async createAndOpenModal(componentRef: any, data: Object, cssClass: string = "", backdropDismiss=true): Promise<OverlayEventDetail<any>> {
    const modal = await this.modalController.create({
      component: componentRef,
      cssClass: cssClass,
      mode: 'md',
      componentProps: data,
      backdropDismiss: backdropDismiss
    });

    await modal.present();
    return modal.onDidDismiss();
  }

  public async createAndOpenPicker(options: JPickerOption[], name: string, oktext='Confirm', canceltext="cancel") {
    const picker = await this.pickerController.create({
      animated: true,
      buttons: [{
        text: canceltext,
        role: 'cancel'
      }, {
        text: oktext,
        role: 'ok'
      }],
      columns: [
        {
          name: name,
          options: options
        }
      ],
      mode: 'md',
    });
    await picker.present();
    let { data } = await picker.onDidDismiss()
    return data ? data[Object.keys(data)[0]] : undefined
  }

  public getDateTime() {
    var today = new Date();
    return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' ' + today.getHours().toString().padStart(2,'0') + ":" + today.getMinutes().toString().padStart(2,'0')
  }

  public checkEmail(email:string): boolean {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      this.notify('Please enter a valid email', 'dark')
      return false
    }
    return true
  }

}

export type newEditDialogSizes = 'big-modal' | 'medium-modal' | 'normal' | 'doubled-modal' | ''
export type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';
export type PredefinedColors = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';
export interface OverlayEventDetail<T = any> {
  data?: T;
  role?: string;
}

export interface JPickerOption {
  text: string,
  value: string
}

export interface UserIdName {
  id: string,
  name: string
}