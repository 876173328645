import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../services/helper.service';
import { LocationsService } from '../../services/locations.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {

  locations: any[]|undefined = undefined

  constructor(private router: Router, public locationsService: LocationsService, private helper: HelperService) { }

  ngOnInit() {
    this.locationsService.GetShort().then(v => {
      console.log(v);
      this.locations = v;
    }).catch(e => {
      this.helper.notify('Could not load locations', 'danger')
    })
  }

  async onAdd() {
    this.router.navigateByUrl('/locations/editor');
  }

  async onClick(itemid) {
    this.router.navigate(['/locations/editor', {id: itemid}]);
  }

}
